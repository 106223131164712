import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/rows/hero'
import Intro from '../components/rows/intro'
import ImgSection from '../components/rows/imgsection'
import Outro from '../components/rows/outro'
import TwoCol from '../components/rows/twocol'

import Banner from '../images/img/hero/our-background-hero.jpg'

export default function OurBackground({data}) { // <== Make sure data is sent to the function

    return (
        <Layout>
        <Seo title="Our Background" />
        <Hero 
            title="Our Background"
            banner={Banner}
        />
        {data.allStrapiOurBackground.edges.map(({ node }) => ( // <== Map out the data from the node.
            <> 
            <div className="intro py-56">
              <Intro key={node.ourbg_intro.id}
                  title={node.ourbg_intro.title}
                  subtitle={node.ourbg_intro.subtitle}
              />
              <TwoCol 
                  white
                  key={node.ourbg_twocol.id}
                  column1={node.ourbg_twocol.description_1}
                  column2={node.ourbg_twocol.description_2}
              />
            </div>
                <ImgSection key={node.ourbg_img.id}
                    buttonOrange
                    subtitle={node.ourbg_img.subtitle}
                    title={node.ourbg_img.title}
                    description={node.ourbg_img.description}      
                    image={node.ourbg_img.image.localFile.publicURL}
                    link={node.ourbg_img.button_title}
                    href={node.ourbg_img.button_link}
                />
                <Outro key={node.ourbg_outro.id}
                    subtitle={node.ourbg_outro.subtitle}
                    title={node.ourbg_outro.title}
                    link={node.ourbg_outro.button_title}
                    href={node.ourbg_outro.button_link}
                />
            </>
        ))}            
        </Layout>    
    )
}


export const query = graphql`
query OurBackground {
  allStrapiOurBackground {
    edges {
      node {
        ourbg_intro {
          id
          subtitle
          title
        }
        ourbg_twocol {
          id
          description_1
          description_2
        }
        ourbg_img {
          id
          subtitle
          title
          description
          button_link
          button_title
          image {
            localFile {
              publicURL
            }
          }
        }
        ourbg_outro {
          id
          subtitle
          title
          button_title
          button_link
        }
      }
    }
  }
}
  
`